import React, { useEffect } from "react";
import { NavLink, Outlet, useLocation } from "react-router-dom";
import "./navbar.css";

const HeaderComponent = () => {
  const location = useLocation();
  const isProductActive = location.pathname === '/aluminium' || location.pathname === '/UPVC';

  useEffect(() => {
    const handleScroll = () => {
      if (window.matchMedia("only screen and (min-width: 1200px)").matches) {
        if (window.scrollY >= 50) {
          document.querySelector(".header").classList.add("fixed-header");
          document.querySelector(".navbar").classList.add("scrolled-navbar");
        } else {
          document.querySelector(".header").classList.remove("fixed-header");
          document.querySelector(".navbar").classList.remove("scrolled-navbar");
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <header className="main-class">
      <div className="abc">
        <div className="header">
          <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <NavLink className="navbar-brand" to="/" onClick={scrollToTop}>
              <img
                src="../../images/home/white wings png.png"
                alt="icon"
                width="180px"
              />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav mr-auto">
                <li className="nav-item">
                  <NavLink
                    className="nav-link spac"
                    to="/"
                    exact
                    onClick={scrollToTop}
                    activeClassName="active"
                  >
                    Home <span className="sr-only">(current)</span>
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    className="nav-link spac"
                    to="/about"
                    onClick={scrollToTop}
                    activeClassName="active"
                  >
                    About Us
                  </NavLink>
                </li>
                <li className={`nav-item nav-link ${isProductActive ? 'active' : ''}`}>
                  <a className="nav-link" style={{ color: isProductActive ? '#0A5096' : 'inherit' }}>
                    Product <i className="fa-solid fa-angle-down"></i>
                  </a>
                  <div className="dropdown">
                    <ul>
                      <li className="dropdown-link">
                        <NavLink
                          to="/aluminium"
                          onClick={scrollToTop}
                          activeClassName="active"
                        >
                          Aluminium
                        </NavLink>
                      </li>
                      <li className="dropdown-link">
                        <NavLink
                          to="/UPVC"
                          onClick={scrollToTop}
                          activeClassName="active"
                        >
                          UPVC
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className="nav-item item-con">
                  <NavLink
                    className="nav-link"
                    to="/contact"
                    onClick={scrollToTop}
                    activeClassName="active"
                  >
                    Contact
                  </NavLink>
                </li>
              </ul>
              <form className="form-inline my-2 my-lg-0">
                <button className="btn my-2 my-sm-0 call" type="submit">
                  <NavLink to="tel:+91 98790 52588">
                    <i className="fa-solid fa-phone"></i> Call Us
                  </NavLink>
                </button>
              </form>
            </div>
          </nav>
          <Outlet />
        </div>
      </div>
    </header>
  );
};

export default HeaderComponent;
