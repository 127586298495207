import React from "react";

function DiffrentCategory() {
  return (
    <div className="diffrent-cate">
      <h3 className="roboto">Category of Casement Windows</h3>
    </div>
  );
}

export default DiffrentCategory;
